import React from "react"
import { graphql } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

//Components
import Layout from "../components/layout"
import Title from "../components/title"
import SEO from "../components/seo"

//styles
import { Container, Accordion, Row, Col } from "react-bootstrap"
import * as styles from "../styles/templates/insurance.module.scss"

export const query = graphql`
  query ($slug: String!) {
    contentfulInsurance(slug: { eq: $slug }) {
      slug
      title
      content {
        raw
      }
    }
  }
`

const Insurance = ({ data }) => {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  )
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      // This is not tested
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <>
            <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          </>
        )
      },
    },
  }

  const insurance = data.contentfulInsurance
  return (
    <Layout>
      <SEO />
      <Title title={insurance.title} />
      <Container className="d-flex justify-content-center my-5">
        <Col lg={5} className={styles.insurance_body}>
          <h1>{insurance.title}</h1>
          <div className={`my-5 pb-5 ${styles.list}`}>
            {renderRichText(insurance.content, options)}
          </div>
          <div className={`p-5 ${styles.content}`}>
            <p className="py-5">
              If you belong to another HMO/IPA or have another office listed;
              you can still come into our office and have it all changed to one
              of our office and physicians.
              <br />
              <br />
              Or contact us on (818) 361-5437 and have it changed over the
              phone.
            </p>
          </div>
        </Col>
      </Container>

      <Container fluid className={`${styles.wrapper} my-0 py-5`}>
        {/* Accordion Section */}
        <Container className={`${styles.container} p-5`}>
          <Row className="py-5 g-5 align-items-center">
            <Col>
              <h1 className={`mb-4`}>Billing Policy</h1>
              <p className={`mb-5`}>
                Our billing policy has recently changed. Please review prior to
                your appointment.
              </p>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Affordable Care Act</Accordion.Header>
                  <Accordion.Body>
                    Under the Affordable Care Act, families are having their
                    insurance changed to new insurance products, as mandated by
                    federal law. As a result, traditional insurance products
                    utilizing legacy PPO networks are no longer accessible for
                    many of our patients, though some who obtain their insurance
                    through unions or government agencies may still have access.
                    The state of California has not adopted the federal
                    extension allowing individuals and families to keep their
                    plans for an additional two years, so in most cases families
                    do not have the choice to keep their legacy networks or the
                    doctors who are in them.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Narrow Networks</Accordion.Header>
                  <Accordion.Body>
                    While our contracts with insurers have not changed.The
                    policies insurers offer has. Insurers have manufactured new,
                    “narrow” networks that have arbitrarily excluded Rose City
                    Pediatrics. Insurers admit that these new, narrow (usually
                    EPO) networks are designed to fulfill insurance companies’
                    needs for a minimum number of “providers or prescribers” and
                    to fulfill the needs of the insurance companies’ members at
                    the lowest cost to the insurance company. Confusingly, many
                    of these new network plans are marketed and advertised as
                    PPOs, which we believe they are not. We have only
                    ascertained the truth after families accessed our services.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Office Billing Policy</Accordion.Header>
                  <Accordion.Body>
                    As a result of the Affordable Care Act, and because of
                    diminished access from new limited assurance networks, and
                    although our existing relationships with insurers has not
                    changed, we have been forced to implement new billing
                    policies to remain viable. If we cannot quickly determine if
                    we will get paid we will charge you $125.00 deposit at the
                    time of service and we will refund when if we get paid. We
                    appreciate that our families value the traditional and high
                    quality services they have come to expect, and that we
                    expect from ourselves. With apologies for any inconvenience
                    this may cause, bat assuring you of our best attention at
                    all times, it is our intention to continue to provide the
                    caliber of care our patients have enjoyed over the years.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default Insurance
